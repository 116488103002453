









import Vue from "vue";
import AuthContainer from "@/components/auth/AuthContainer.vue";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm.vue";

export default Vue.extend({
  name: "ForgotPassword",
  components: {
    AuthContainer,
    ForgotPasswordForm,
  },
});
