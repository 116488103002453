









import Vue from "vue";
import AuthContainer from "@/components/auth/AuthContainer.vue";
import TwoFactorForm from "@/components/auth/TwoFactorForm.vue";
import { apiAxios } from "@/store/apiAxios";
export default Vue.extend({
  name: "ForgotPassword",
  components: {
    AuthContainer,
    TwoFactorForm,
  },
  mounted() {
    if (this.$route.params.t == "email") {
      apiAxios.post("/logout");
    } else if (this.$route.params.t == "sms") {
      apiAxios.post("/logout");
    }
  },
});
