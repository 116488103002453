









































import Vue from "vue";

import AuthService from "@/services/AuthService";
import { ErrorResult } from "@/utils/helpers";

export default Vue.extend({
  name: "ForgotPassword",
  components: {},
  data() {
    return {
      email: null,
      error: {} as ErrorResult,
      message: "",
    };
  },
  methods: {
    forgotPassword() {
      this.error = {};
      this.message = "";
      const payload = {
        email: this.email,
      };
      const logs = {
        email: this.email,
        type: "forgot password",
        step: "request email to reset password",
      };
      AuthService.forgotPassword(payload)
        .then(() => {
          AuthService.addLog(logs),
            this.$router.push({
              name: "Login",
              params: { pass_reset: "process" },
            });
        })
        .catch(() =>
          this.$router.push({
            name: "Login",
            params: { pass_reset: "process" },
          })
        );
    },
  },
});
