































import Vue from "vue";
import { apiAxios } from "@/store/apiAxios";
import AuthService from "@/services/AuthService";
import i18n from "@/i18n";

export default Vue.extend({
  name: "TwoFactorForm",
  components: {},
  props: ["email", "password"],
  data() {
    return {
      code: null,
      error: { email: [], password: [], code: "" },
      message: "",
    };
  },
  methods: {
    verifyCode() {
      if (this.$route.params.t === "email") {
        apiAxios
          .post("/api/check-tfa-email", {
            code: this.code,
            email: this.$route.params.email,
          })
          .then((res) => {
            if (res.data.status === 204) {
              this.error.code = "";
              apiAxios
                .post("/login", {
                  email: this.$route.params.email,
                  password: this.$route.params.password,
                })
                .then(() => {
                  this.getUser();
                });
            } else {
              this.error.code = i18n.t("auth.code-not-valid");
            }
          });
      } else {
        const payload = {
          email: this.$route.params.email,
          type: "login",
          step: "login, code verified",
        };
        apiAxios
          .post("/two-factor-challenge", { code: this.code })
          .then((res) => {
            if (res.status === 204) {
              this.error.code = "";
              AuthService.addLog(payload).then(() => {
                this.getUser();
              });
            } else {
              this.error.code = i18n.t("auth.code-not-valid");
            }
          });
      }
    },
    async getUser() {
      const authUser = await this.$store.dispatch("auth/getAuthUser");
      if (authUser) {
        if (
          process.env.VUE_APP_SHOW_ADMIN_PANEL === "true" &&
          authUser.role_id === 1
        ) {
          this.$router.push("/admin/dashboard").catch();
        } else {
          this.$router.push("/").catch();
        }
      } else {
        const error = Error(
          "Unable to fetch user after login, check your API settings."
        );
        error.name = "Fetch User";
        throw error;
      }
    },
  },
});
